import createSagaMiddleware from '@redux-saga/core';

import { applyMiddleware, createStore } from 'redux';

import { createBrowserHistory } from 'history';

import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './rootReducer';

import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const localStorage = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(rootSaga);

  return localStorage;
};

export const store = configureStore();

export const history = createBrowserHistory();