import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IListClassTypeResponse } from '../../Interfaces/response/ClassType/IClassTypeResponse';

export class ClassAPI {
  public static async listClassType(
    context: QueryFunctionContext
  ): Promise<IListClassTypeResponse> {
    return await Http.fetcher<IListClassTypeResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/ClasseProduto/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}
