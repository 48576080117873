export const forgotPasswordSuccessEN = {
  title: 'Check your E-mail',

  successMsg: 'We sent instructions for your password recovery to the email',
  successEndMsg:
    'Check your E-mail and follow all instructions to recover your password',

  ok: 'Ok',

  infoLabel: "Didn't receive the E-mail? Check your spam or",
  tryOtherMail: 'try other E-mail address',
};

export const forgotPasswordSuccessPT = {
  title: 'Confira seu e-mail',

  successMsg: 'Enviamos instruções de recuperação da sua senha para o e-mail',
  successEndMsg:
    'Acesse seu e-mail e siga as instruções para redefinir a senha.',
  ok: 'Voltar ao login',

  infoLabel: 'Não recebeu o e-mail? Confira no filtro de spam ou',
  tryOtherMail: 'Tente novamente',
};
