import { call, put } from 'redux-saga/effects';
import { NotificationAPI } from '../../Data/API/NotificationApi';
import { messageErrorToast } from '../../Utils/MessageErrorToast';
import {
  GetNotificationsRequestAction,
  NotificationActions,
} from './Notification.actions';

export function* getNotifications({ payload }: GetNotificationsRequestAction) {
  try {
    const { data } = yield call(NotificationAPI.getNotifications, payload);

    yield put(NotificationActions.getNotificationsSuccess(data));
  } catch (error: any) {
    messageErrorToast(error);

    yield put(NotificationActions.getNotificationsFailure(error.message));
  }
}
