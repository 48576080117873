import React from 'react';

import style from './Notification.module.scss';

const Notification: React.FC = () => {
	return (
		<div className={style['divNotification']}>
			Notification
		</div>
	)
}

export default Notification;