import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopOver } from '../../../PopOver';
import { Text } from '../../../Text';
import { CompanyButton } from '../../../Button/CompanyButton';

import styles from './ContextButton.module.scss';
import { contextItems } from './ContextButtons.items';

const HeaderMenutitle = (t: any) => {
  return (
    <div className={styles['divTitle']}>
      <Text
        className={styles['title']}
        type="paragraph2"
        color="text-400"
        children={t('header.contextTitle')}
      />
      <div className={styles['line']} />
    </div>
  );
};

const HeaderMenucontent = (
  company: (isCompany: any) => void,
  visible: (isVisible: boolean) => void
) => {
  return (
    <div className={styles['divContent']}>
      {contextItems.map((item, index) => (
        <CompanyButton
          className={styles['button']}
          type="secondary-company"
          children={item.company}
          company={item.context}
          onClick={() => {
            company(item.id);
            visible(false);
          }}
          key={`content-contextButton-${index}`}
        />
      ))}
    </div>
  );
};

export const ContextButton: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [company, setCompany] = useState<number>(0);

  const { t } = useTranslation();

  return (
    <PopOver
      visible={visible}
      onOpenChange={() => setVisible(!visible)}
      placement="bottomRight"
      trigger="click"
      title={HeaderMenutitle(t)}
      content={HeaderMenucontent(
        (isComapny) => setCompany(isComapny),
        (isVisible) => setVisible(isVisible)
      )}
    >
      <CompanyButton
        className={styles['company-button']}
        compapyButton={contextItems[company].context}
        type="primary-company"
        children={contextItems[company].company}
        rightIcon="chevron-down"
      />
    </PopOver>
  );
};
