import { Http } from '../../../Utils/Http';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class emailAPI {
  public static async isEmailAlreadyRegistred(
    email?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!email) return null;
    return await Http.fetcher('/identity/v1/EmailValidator', {
      method: 'POST',
      body: JSON.stringify({ email }),
    });
  }
}
