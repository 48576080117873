import React, { FC } from 'react'
import { Radio as AntRadio } from 'antd'
import { Text } from '../../Text'

import styles from './Radio.module.scss'
import './Radio.override.scss'
import { Tooltip } from '../../Tooltip'

export interface IRadio {
	label?: string
	disabled?: boolean
	value: any
	checked?: boolean
	onChange?: (e: any) => void

	tooltip?: string
}

export const RadioButton: FC<IRadio> = ({
	label,
	disabled,
	value,
	checked,
	onChange,
	tooltip,
}) => (
	<Tooltip title={tooltip} showMe={!!tooltip}>
		<div className={styles['Radio']}>
			<AntRadio
				disabled={disabled}
				checked={checked}
				onClick={onChange}
				value={value}
			/>
			{label ? (
				<Text type='ui-sub-content' color='text-50'>
					{label}
				</Text>
			) : null}
		</div>
	</Tooltip>
)
