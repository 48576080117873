import { AxiosResponse } from 'axios'
import { Http } from '../../Utils/Http'
import { IGetNotificationResponse } from '../Interfaces/response/Notification/IGetNotificationResponse'

export class NotificationAPI {
    public static async getNotifications(onlyUnread: boolean): Promise<AxiosResponse<IGetNotificationResponse[]>> {
        const axios = await Http.axios()
        return axios.get<IGetNotificationResponse[]>(`/Notification/v1/Notification/index?onlyUread=${onlyUnread}`)
    }
}
