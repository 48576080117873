export const districtEN = {
    create: {
        new: 'New District',
        save: 'Save Changes',
        back: 'Back',

        name: 'District Name',
        namePlaceholder: 'Insert a District Name',

        state: 'State',
        statePlaceholder: 'Pick a State',

        city: 'City',
        cityPlaceholder: 'Pick a City',
    },
    list: {
        title: 'Districts',
        searchPlaceholder: 'Search',
        new: 'New',

        table: {
            district: 'District',
            city: 'City',
            state: 'State',

            editAction: 'Edit',
            deleteAction: 'Delete',
        },
    },
}

export const districtPT = {
    create: {
        new: 'Novo Bairro',
        save: 'Salvar Alteraçoes',
        back: 'Voltar',

        name: 'Nome do Bairro',
        namePlaceholder: 'Inserir o nome do bairro',

        state: 'Estado',
        statePlaceholder: 'Selecione um estado',

        city: 'Cidade',
        cityPlaceholder: 'Selecione a Cidade',
    },
    list: {
        title: 'Bairros',
        searchPlaceholder: 'Pesquisa',
        new: 'Novo',

        table: {
            district: 'Bairro',
            city: 'Cidade',
            state: 'Estado',

            editAction: 'Editar',
            deleteAction: 'Deletar',
        },
    },
}
