import React from 'react';
import { CompanyBillingsCard } from '../..';

interface ICompanyBillingsCard {
  radioCompanyGroup: boolean;
  radioBillings: boolean;
  onChangeRadioBillings: (value: boolean) => void;
}

export const CreateCompanyBillingsCard: React.FC<ICompanyBillingsCard> = ({
  radioCompanyGroup,
  onChangeRadioBillings,
  radioBillings,
}) => (
  <CompanyBillingsCard
    editForm={false}
    radioCompanyGroup={radioCompanyGroup}
    onButtonChange={(x) => onChangeRadioBillings(x)}
    radioBillings={radioBillings}
  />
);
