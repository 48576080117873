export const tableEN = {
	headerTable: {
		new: 'New',
		edit: 'Edit',
		delete: 'Delete'
	},
	status: {
		active: 'Active',
		invalid: 'Invalid',
		pending: 'Pending',
		disabled: 'Disabled',
	},
	pagination: {
		itemsPerPage: 'Items per page:',
		Items: 'Items'
	}
}

export const tablePT = {
	headerTable: {
		new: 'Novo',
		edit: 'Editar',
		delete: 'Excluir'
	},
	status: {
		active: 'Ativo',
		invalid: 'Inválido',
		pending: 'Pendente',
		disabled: 'Desativado',
	},

	pagination: {
		itemsPerPage: 'Itens por página:',
		Items: 'Itens'
	}
}
