import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translations from './locales';

export enum Languages {
  ptBR = 'pt-BR',
  enUS = 'en-US',
}

export enum TypeLanguages {
  'pt-BR' = 'Português (Brasil)',
  'en-US' = 'English (United States)',
}

export enum getTypeLanguage {
  'Português (Brasil)' = 'pt-BR',
  'English (United States)' = 'en-US',
}

const i18nConfig = {
  resources: translations,
  fallbackLng: Languages.enUS,
  defaultNS: 'translations',
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
