import React, { ReactNode } from 'react';

import { Text } from '../../../Components'
import { IText } from '../../../Components/Text';

import styles from './TableConfig.module.scss';

export interface ITableConfig {
	head: string;
	body: IText[];
	rightItem: ReactNode;
	widthRightItem?: number;
}

export const TableConfig: React.FC<ITableConfig> = ({ head, body, rightItem, widthRightItem }) => {
	return (
		<div className={styles['container']}>
			<Text type='ui-tiny-content' color='text-50' children={head} />
			<div className={styles['description']}>
				{
					body.map((x, index) => (
						<Text type={x.type} color={x.color} children={x.children} key={`table-config-${index}`} />
					))
				}
			</div>
			<div className={styles['rightItem']} style={widthRightItem ? { width: widthRightItem } : undefined}>
				{
					rightItem
				}
			</div>
		</div>
	);
}