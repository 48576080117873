export const systemConfigEN = {
    personalization: 'Personalizarion',

    currency: 'Currency',
    currencyPage: {
        title: 'Currency selection',
        description: 'Select the currency in which all system operations will be performed',

        inputCurrency: 'Currency',
        button: 'Save',

				success: 'Updated currency!'
    },
}

export const systemConfigPT = {
    personalization: 'Personalização',

    currency: 'Moeda',
    currencyPage: {
        title: 'Seleção de moeda',
        description: 'Selecione a moeda na qual todas as operações do sistema serão realizadas',

        inputCurrency: 'Moeda',
        button: 'Salvar',

				success: 'Moeda atualizada!'
    },
}
