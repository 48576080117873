import React from 'react';
import { useTranslation } from 'react-i18next';
import { statusTypes } from '../../Utils/StatusUtils';
import { Text } from '../';
import styles from './SimpleStatus.module.scss';
import './SimpleStatus.override.scss';

export interface IStatus {
  type: statusTypes;

  darkMode: boolean;
}

export const SimpleStatus: React.FC<IStatus> = ({ type, darkMode }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`${type}-status ${darkMode ? 'darkmode' : ''} ${
        styles['status']
      }`}
    >
      <Text
        type="ui-tiny-bold"
        children={
          type === 'success' ? t('common.activate') : t('common.inactivate')
        }
      />
    </div>
  );
};
