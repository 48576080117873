import { AxiosResponse } from 'axios';
import { Http, ODataResponse, IQueryListParams } from '../../../Utils/Http';
import { IQueryParams } from '../../../Components/Table';
import {
  ILossesListResponse,
  IProductLossListData,
  IReasonLossListResponse,
} from '../../Interfaces/response/Loss/ILossResponse';
import { ILossDetailResponse } from '../../Interfaces/response/Loss/ILossDetailResponse';
import { IUpdateLoss } from '../../Interfaces/request/Loss/IUpdateLoss';
import { ICreateLosse } from '../../Interfaces/request/Loss/ILossesRequest';
import { QueryFunctionContext } from '@tanstack/query-core';

export class LossesAPI {
  public static async createLosse(body: ICreateLosse) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Perda`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];

    return res;
  }

  public static async getLossesList(
    context: QueryFunctionContext
  ): Promise<ILossesListResponse> {
    return await Http.fetcher<ILossesListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/Perda/ListarDetalhado',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getLoss(
    context: any
  ): Promise<ILossDetailResponse | null> {
    if (context.queryKey[1] !== undefined)
      return await Http.fetcher<ILossDetailResponse>(
        `/pharma/estoque/v1/Perda/${context.queryKey[1]}`
      );
    else return null;
  }

  public static async updateLoss(body: IUpdateLoss) {
    const res = await Http.fetcher(`/pharma/estoque/v1/Perda`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];

    return res;
  }

  public static async getReasonLossListDropdown(
    context: QueryFunctionContext
  ): Promise<IReasonLossListResponse> {
    return await Http.fetcher<IReasonLossListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/MotivoPerda/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }
}

export class ProductLossAPI {
  public static async productLossList(
    body: IQueryParams
  ): Promise<AxiosResponse<ODataResponse<IProductLossListData>>> {
    const axios = await Http.axios();
    return axios.get<ODataResponse<IProductLossListData>>(
      Http.AddODataQueryParams('/estoque/odata/v1/Produto', body)
    );
  }
}
