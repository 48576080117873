export const isFormError = (errors: any): boolean => {
  if (!errors) return true;

  for (let value in errors) {
    if (errors[value] !== undefined) {
      if (typeof errors[value] === 'string') return true;
      return isFormError(errors[value]);
    }
  }
  return false;
};
