import React from 'react';

import styles from './Spinner.module.scss';

export interface ISpinner {
	className?: string;
}

const Spinner: React.FC<ISpinner> = ({ className }) => {
	return (
		<div className={`${className} ${styles['loader']}`}><div /><div /><div /><div /></div>
	)
}

export default Spinner;