export const loginEN = {
    title: 'Access your Account',

    email: 'E-mail',
    emailPlaceholder: 'Insert your E-mail',

    pass: 'Password',
    passPlaceholder: 'Insert your Password',

    rememberMe: 'Remember me',

    forgotPassword: 'Forgot your password?',

    action: 'Login',

    errors: {
        invalidMail: 'Invalid E-mail',
        requiredMail: 'E-mail is required',

        requiredPass: 'Password is required',
    },
}

export const loginPT = {
    title: 'Acesse sua conta',

    email: 'E-mail',
    emailPlaceholder: 'Informe seu e-mail',

    pass: 'Senha',
    passPlaceholder: 'Informe sua senha',

    rememberMe: 'Lembrar dados',

    forgotPassword: 'Esqueceu a senha?',

    action: 'Acessar',

    errors: {
        invalidMail: 'E-mail Inválido',
        requiredMail: 'E-mail é Obrigatório',

        requiredPass: 'Senha é obrigatória',
    },
}
