import React, { FC, ReactNode } from 'react';

import { Text } from '../../../Components'
import { ITableConfig, TableConfig } from '../TableConfig';

import styles from './TablePreference.module.scss';

export interface ITableLayout {
	title: string;
	description?: string;
	tableConfig?: ITableConfig[];
	className?: string;
	body?: ReactNode;
}

export const TableLayout: FC<ITableLayout> = ({ title, description, tableConfig, className, body }) => {
	return (
		<>
			<div className={`${className} ${styles['container']}`}>
				<Text type='ui-main-bold' color='text-50' children={title} />
				{
					description && <Text className={styles['description']} type='paragraph2' color='text-100' children={description} />
				}
				{
					tableConfig && tableConfig.map((conf, index) => <TableConfig
						head={conf.head}
						body={conf.body}
						rightItem={conf.rightItem}
						widthRightItem={conf.widthRightItem}
						key={`table-layout-${index}`}
					/>)
				}
				{
					body && body
				}
			</div>
			<div className={styles['margin-bottom']} />
		</>
	)
}