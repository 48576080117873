import { AxiosResponse } from 'axios';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { IPagination } from '../../../Components/Table';
import {
  ICreateStorageLocation,
  IPatchStorageLocationStatusRequest,
  IEditStorageLocationRequest,
  IDeleteStorageLocationRequest,
} from '../../Interfaces/request/StorageLocation/IStorageLocationRequest';
import {
  IGetStorageLocationResponse,
  IProductStorageLocationListResponde,
  IStorageLocationListDropDownResponse,
} from '../../Interfaces/response/StorageLocation/IStorageLocationResponse';
import { IStorageLocationListResponse } from '../../Interfaces/response/StorageLocation/IStorageLocationResponse';
import { QueryFunctionContext } from '@tanstack/react-query';
import { IBaseResponse } from '../../Interfaces/response/Base/IBase';

export class StorageLocationApi {
  public static async createStorageLocation(body: ICreateStorageLocation) {
    const res = await Http.fetcher('/pharma/estoque/v1/LocalEstoque', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getStorageLocationList(
    context: QueryFunctionContext
  ): Promise<IStorageLocationListResponse> {
    return await Http.fetcher<IStorageLocationListResponse>(
      Http.AddQueryParams(
        '/pharma/estoque/v1/LocalEstoque/Listar',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getStorageLocationListAccountmanager(
    body: IPagination
  ): Promise<AxiosResponse<any>> {
    const axios = await Http.axios();
    return axios.get<any>(
      Http.AddQueryParams('/pharma/estoque/v1/LocalEstoque/DropDown', body)
    );
  }

  public static async getListStorageLocationDropDown(
    context: any
  ): Promise<IStorageLocationListDropDownResponse> {
    return await Http.fetcher(
      Http.AddQueryParams(
        '/pharma/estoque/v1/LocalEstoque/ListarAtivo',
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getCurrentStorageLocation(
    context: QueryFunctionContext
  ): Promise<IGetStorageLocationResponse> {
    return await Http.fetcher<IGetStorageLocationResponse>(
      `/pharma/estoque/v1/LocalEstoque/${context.queryKey[1]}`
    );
  }

  public static async patchStorageLocationStatus(
    body: IPatchStorageLocationStatusRequest
  ) {
    const res = await Http.fetcher(
      `/pharma/estoque/v1/LocalEstoque/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async editStorageLocation(body: IEditStorageLocationRequest) {
    const res = await Http.fetcher(`/pharma/estoque/v1/LocalEstoque`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });

    if (!res) return [];
    return res;
  }

  public static async isStorageLocationNameAlreadyRegistred(
    descricao?: string
  ): Promise<IBaseResponse<{}> | null> {
    if (!descricao) return null;
    return await Http.fetcher(
      '/pharma/estoque/v1/LocalEstoque/ValidacaoDescricao',
      {
        method: 'POST',
        body: JSON.stringify({ descricao }),
      }
    );
  }

  public static async deleteStorageLocation(
    body: IDeleteStorageLocationRequest
  ) {
    const res = await Http.fetcher(`/pharma/estoque/v1/LocalEstoque`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getInventoryByStockLocationList(
    context: QueryFunctionContext
  ): Promise<IProductStorageLocationListResponde> {
    let url = Http.AddQueryParams(
      `/pharma/estoque/v1/SaldoEstoque/ListarDetalhadoPorProduto`,
      context.queryKey[1] as IQueryListParams
    );
    url += `&localEstoqueExternalId=${context.queryKey[2]}`;
    return await Http.fetcher<IProductStorageLocationListResponde>(url);
  }
}
