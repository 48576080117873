export interface IContextItems {
	id: number;
	company: string;
	context: 'matriz' | 'filial';
}

export
const contextItems: IContextItems[] =[
	{
		id: 0,
		company: 'São João do Sul',
		context: 'matriz'
	},
	{
		id: 1,
		company: 'São João do Sul',
		context: 'filial'
	},
	{
		id: 2,
		company: 'São João do Sul',
		context: 'filial'
	}
];