import { FC, ReactNode, useState } from 'react';
import { Radio } from 'antd';

export interface IRadioGroup {
  children: ReactNode;
  className?: string;
  valueChecked?: number;

  defaultValue?: any;
}

export const RadioGroup: FC<IRadioGroup> = ({
  children,
  className,
  valueChecked,
  defaultValue,
}) => {
  const [value, setValue] = useState(1);

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <>
      {defaultValue ? (
        <Radio.Group
          className={className}
          onChange={onChange}
          defaultValue={defaultValue}
        >
          {children}
        </Radio.Group>
      ) : (
        <Radio.Group
          className={className}
          value={valueChecked ? valueChecked : value}
          onChange={onChange}
        >
          {children}
        </Radio.Group>
      )}
    </>
  );
};
