import React from 'react';
import InputMask from 'react-input-mask';
import { IMask, Mask } from '../../../Utils/MasksUtils';
import { Input } from 'antd';
import { Icon } from '../..';
import { Loading } from '../../Loading';
import { ITextInput } from '../../Inputs/TextInput';
import { InputLabel } from '../../Inputs/Label';

import './TextInput.override.scss';
import styles from './TextInput.module.scss';

export interface ITextInputWitchMask extends Omit<ITextInput, 'name'> {
  mask?: IMask;
  onBlur?: (value: string) => void;
  loading?: boolean;
  customMask?: string;

  value?: string;
}

export const TextInputWithMask: React.FC<ITextInputWitchMask> = ({
  mask,
  label,
  required,
  withoutMarginBottom,
  placeHolder,
  success,
  rightIcon,
  leftIcon,
  disabled,
  className,
  loading,
  customMask,
  onChange,
  value,
}) => {
  return (
    <div
      className={`${withoutMarginBottom ? '' : styles['margin-bottom']} ${
        styles['Container']
      }`}
    >
      <InputLabel label={label} required={required} />

      <div
        className={`
              	${styles['input-container']}
              	${className}
              	${leftIcon ? styles['with-left-icon'] : null}
              	${rightIcon ? styles['with-right-icon'] : null}
              	${success ? styles['success'] : null}`}
      >
        {leftIcon ? (
          <Icon
            className={styles['Left-Icon']}
            icon={leftIcon}
            size="M"
            color="text-50"
          />
        ) : null}
        <InputMask
          value={value}
          mask={customMask ? customMask : Mask(mask ? mask : 'cep')}
          disabled={disabled}
          placeholder={placeHolder}
          onChange={(x) => {
            onChange && onChange(x.target.value);
          }}
          children={
            <Input
              className={styles['TextInput']}
              placeholder={placeHolder}
              disabled={disabled}
            />
          }
        />

        {rightIcon?.icon ? (
          <Icon
            className={styles['Icon']}
            icon={rightIcon.icon}
            size="M"
            color="text-50"
          />
        ) : null}
        {loading ? <Loading className={styles['loading']} /> : null}
      </div>
    </div>
  );
};
