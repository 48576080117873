import React, { FC } from 'react';
import { Icon } from '../../../..';
import { IconName } from '../../../../Icon/IconClasses';
import { Text } from '../../../../';
import { Color, Colors } from '../../../../../Utils/ColorUtils';

import styles from './SectorItem.module.scss';

export interface ISectorItem {
  icon: IconName;
  backgroundColor: Color;
  text: string;

  onClick?: () => void;
}

export const SectorItem: FC<ISectorItem> = ({
  icon,
  text,
  onClick,
  backgroundColor,
}) => (
  <>
    <div
      className={styles['icon-container']}
      style={{ backgroundColor: Colors[backgroundColor] }}
    >
      <Icon icon={icon} color="white" />
    </div>
    <div className={styles['text-container']}>
      <Text type="ui-tiny-content" color="text-50">
        {text}
      </Text>
    </div>
  </>
);
