import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Text } from '../../';
import styles from './LinkButton.module.scss';

export interface ILinkButton {
  link: string;
  text: string;
}

export const LinkButton: FC<ILinkButton> = ({ link, text }) => {
  return (
    <Link
      className={styles['container']}
      to={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Text type="ui-tiny-content" children={text} />
      <Icon className={styles['icon']} icon="external-link" size="SS" />
    </Link>
  );
};
