import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import { ICreateBalanceAdjustments } from '../../Interfaces/request/BalanceAdjustments/ICreateBalanceAdjustments';
import {
  IBalanceAdjustmentList,
  IBalanceAdjustmentsResponse,
} from '../../Interfaces/response/BalanceAdjustments/IBalanceAdjustmentsResponse';

export class BalanceAdjustmentsAPI {
  public static async getBalanceAdjustmentsList(
    context: QueryFunctionContext
  ): Promise<IBalanceAdjustmentList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<IBalanceAdjustmentList>(
      Http.AddQueryParams(
        `/pharma/estoque/v1/AjusteSaldoEstoque/ListarDetalhado`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getBalanceAdjustment(
    context: any
  ): Promise<IBalanceAdjustmentsResponse | null> {
    if (context.queryKey[1] !== undefined)
      return await Http.fetcher<IBalanceAdjustmentsResponse>(
        `/pharma/estoque/v1/AjusteSaldoEstoque/${context.queryKey[1]}`
      );
    else return null;
  }

  public static async postBalanceAdjustment(body: ICreateBalanceAdjustments) {
    const res = await Http.fetcher(`/pharma/estoque/v1/AjusteSaldoEstoque`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }
}
