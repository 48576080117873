import { IGetCompanyResponse } from '../../../Data/Interfaces/response/Company/ICompanyResponse';
import { MaskForCNPJ } from '../../../Utils/MasksUtils';

export const initialValues = (data: IGetCompanyResponse) => ({
  externalId: data.externalId,
  razaoSocial: data.razaoSocial,
  nomeFantasia: data.nomeFantasia,
  cnpj: MaskForCNPJ(data?.cnpj),
  inscricaoEstadual: data.inscricaoEstadual,
  inscricaoMunicipal: data.inscricaoMunicipal,
  empresaPagadoraExternalId: data.empresaPagadoraExternalId,
  cnae: data.cnae,
  email: data.email,
  site: data.site,
  telefone: data.telefone,
  tipoMoedaId: data.tipoMoedaId,
});
