export const forgotPasswordEN = {
    title: 'Recover Your Password',

    newPass: 'New Password',
    newPassPlaceholder: 'Insert your new Password',

    confirmPass: 'Confirm Password',
    confirmPassPlaceholder: 'Confirm your password',

    ok: 'Send',
    back: 'Back to Login Page',

    errors: {
        passMin: 'Password should have a minimum of 8 characters',
        passMax: 'Password should have a maximum of 25 characters',
        passRequired: 'Password is required',

        differenPassword: 'As senhas não correspondem',
        confirmPassRequired: 'Confirm Password is required',
    },
}

export const forgotPasswordPT = {
    title: 'Alteração de senha',

    newPass: 'Nova Senha',
    newPassPlaceholder: 'Informe sua nova senha',

    confirmPass: 'Confirmar senha',
    confirmPassPlaceholder: 'Confirme sua nova senha',

    ok: 'Alterar senha',
    back: 'Voltar ao login',

    errors: {
        passMin: 'A senha precisa ter no mínimo 8 caracteres',
        passMax: 'Senha deve ter no máximo 25 caracteres',
        passRequired: 'Senha é obrigatória',

        differenPassword: 'As senhas não correspondem',
        confirmPassRequired: 'A Confirmação da senha é obrigatoria',
    },
}
