import { Field, FormikValues, useFormikContext } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '../..';

import styles from './PermissionToggle.module.scss';

type PermissionType = boolean | 'inherited' | 'not-inherited';

interface IPermissionToggle {
  name: string;
  hasUndetermined?: boolean;
  disabled?: boolean;
  inheritedFrom?: string[];
  onChange?: (
    name: string,
    prevValue: PermissionType,
    newValue: PermissionType
  ) => void;
}

export const PermissionToggle: FC<IPermissionToggle> = ({
  name,
  disabled,
  inheritedFrom = [],
  hasUndetermined = true,
  onChange,
}) => {
  const { t } = useTranslation();
  const form = useFormikContext<FormikValues>();

  const onClick = (value: PermissionType) => {
    if (!disabled) {
      const previousValue = form.values[name];

      if (previousValue === value) return;

      form.setFieldValue(name, value);
      onChange && onChange(name, previousValue, value);
    }
  };

  return (
    <Field as="span" name={name}>
      <div
        className={`${styles['Container']} ${
          styles[`value-${form.values[name]}`]
        } ${!hasUndetermined && styles['Undetermined']}`}
      >
        <Tooltip title={t('common.permissionType.deny')} showMe={!disabled}>
          <div
            className={`${styles['item']} ${styles['false']}`}
            onClick={() => onClick(false)}
          >
            <Icon icon="close-x" color="text-300" size="SM" />
          </div>
        </Tooltip>

        {hasUndetermined && (
          <Tooltip
            title={`${t(
              'common.permissionType.inherited'
            )} ${inheritedFrom.join(', ')}`}
            showMe={!disabled}
          >
            <div
              className={`${styles['item']} ${styles['inherited']}`}
              onClick={() =>
                onClick(
                  //Should not toggle
                  // props.field.value === 'inherited'
                  //   ? 'not-inherited'
                  //   : 'inherited',
                  'inherited'
                )
              }
            >
              <p>/</p>
            </div>
          </Tooltip>
        )}

        <Tooltip title={t('common.permissionType.allow')} showMe={!disabled}>
          <div
            className={`${styles['item']} ${styles['true']}`}
            onClick={() => onClick(true)}
          >
            <Icon icon="check" color="text-300" size="SM" />
          </div>
        </Tooltip>
      </div>
    </Field>
  );
};
