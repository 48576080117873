export const headerEN = {
    preference: 'Preferences',
    manageAccount: 'Manage Account',
    configuration: 'Configuration',
    about: 'About',
		help: 'Help',
    logout: 'Logout',
    matriz: 'HQ',
    filial: 'SUBSIDIARY',
    contextTitle: 'Change to Headquarters / Subsidiary',
    searchPlaceHolder: 'Search for people, products ...',
		advancedSearch: 'Advanced Search',

    notifications: {
        unread: 'Unread',
        allNotifications: 'All notifications',
        markAsRead: 'Mark as read',
    },
}

export const headerPT = {
    preference: 'Preferências',
    manageAccount: 'Gerenciar Conta',
    configuration: 'Configuraçoes',
    about: 'Sobre',
		help: 'Ajuda',
    logout: 'Sair',
    matriz: 'MATRIZ',
    filial: 'FILIAL',
    contextTitle: 'Alterar para Matriz/Filial',
    searchPlaceHolder: 'Procure por pessoas, produtos...',
		advancedSearch: 'Pesquisa avançada',

    notifications: {
        unread: 'Não lidas',
        allNotifications: 'Todas',
        markAsRead: 'Marcar como lidas',
    },
}
