import React from 'react';

import styles from './Message.module.scss';
import './Message.override.scss';
import { Text } from '../../Text';

export type MessageType = 'success' | 'error' | 'warning' | 'brand';

export const Message: React.FC = () => {
	return (
		<div id='message' children={<Text type='paragraph-bold' color='white' children='' />} />
	)
}

export function AlertMessage(type: MessageType, text: string, time?: number) {
	setTimeout(() => closeMassege(type), time ? time : 5000)
	let css = document.getElementById('message');

	changeText(css, text)
	return css ?
		css.className = `${type + '-message'} ${styles['divMessage']} open`
		: null
}

function closeMassege(type: MessageType) {
	let css = document.getElementById('message')
	return css ?
		css.className = `${type + '-message'} close`
		: null
}

function changeText(css: any, text: string) {
  return css ? css.innerHTML = text : null
}