export const ShortNameUtils = (name: string, lastName?: string) => {
  if (!name) return;
  if (lastName) return name[0].toUpperCase() + lastName[0].toUpperCase();
  else {
    const hasSpace = name?.indexOf(' ') > -1;

    if (hasSpace) {
      return name
        .split(' ')
        .reduce((firstName: any, lastName: any) => firstName[0] + lastName[0])
        .toUpperCase();
    }

    return `${name[0]}${name[1]}`?.toUpperCase();
  }
};
