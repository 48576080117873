import React from 'react';

import { Text } from '../'

import styles from './Badge.module.scss'

export type BadgeType = 'primary' | 'text' | 'link' | undefined

export interface IBadge {
	type: BadgeType,
	amount: number

	className?: string
}

export const Badge: React.FC<IBadge> = ({ type, amount, className }) => {
	return (
		<div
			className={`
				${styles['badge']} 
				${styles['badge-' + type]} 
				${amount > 9 && amount < 99 ? styles['medium-badge'] : amount > 99 ? styles['large-badge'] : null}
				${className ? className : ''}
			`}
		>
			<Text type='ui-tiny-bold' color={type === 'primary' || type === 'link' ? 'primary-base' : 'text-50'} children={amount > 99 ? '99+' : amount ? amount.toString() : null} />
		</div>
	)
}