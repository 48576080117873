import React, { FC } from 'react';
import { Switch as AntSwitch } from 'antd';
import { Text } from '../../Text';

import styles from './Switch.module.scss';
import './Switch.override.scss';

export interface ISwitch {
	label?: string;
	disabled?: boolean;
	checked?: boolean;
	onChange?: (e: any) => void;
}

export const Switch: FC<ISwitch> = ({ label, disabled, checked, onChange }) => {
	return (
		<div className={styles['Switch']}>
			<AntSwitch
				disabled={disabled}
				checked={checked}
				onClick={onChange}
			/>
			{
				label ? <Text type="ui-sub-content" color="text-50">{label}</Text> : null
			}
		</div>
	)
};