import React, { ReactNode } from 'react';
import { IDeleteModalTranslations, ManyItemsBody, SingleItemBody } from '.';

import styles from './DeleteModal.module.scss';

export const deleteModalTitle = (
  isSingleItem: boolean,
  itemNames: string[][],
  t: any,
  customEndTitle?: string,
  title?: string,
  translations?: IDeleteModalTranslations
) => {
  if (title) {
    return title;
  } else if (translations?.modalTitle) {
    return t(translations?.modalTitle);
  } else if (isSingleItem) {
    return `${t('modal.delete')} ”${itemNames[0][0]}${
      itemNames[0][1] ? ` ${itemNames[0][1]}` : ''
    }”?`;
  } else
    return `${t('modal.delete')} ${itemNames.length} ${
      customEndTitle ? customEndTitle : t('modal.deleteModal.many.items')
    }?`;
};

export const deleteModalBody = (
  body: ReactNode,
  itemNames: string[][],
  translations?: IDeleteModalTranslations
) => {
  if (body) {
    return <div className={styles['body-container']}>{body}</div>;
  } else if (itemNames.length > 1) {
    return <ManyItemsBody itemNames={itemNames} translations={translations} />;
  } else {
    return <SingleItemBody itemNames={itemNames} translations={translations} />;
  }
};
