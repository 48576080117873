import React, { ReactNode } from 'react';

import styles from './ContainerPage.module.scss';

interface IContainerPage {
  children: ReactNode;
}

export const ContainerPage: React.FC<IContainerPage> = ({ children }) => {
  return <div className={styles['container']}>{children}</div>;
};
