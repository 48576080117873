import React from 'react'
import { IColumn } from '../..'
import { Skeleton } from '../../..'

/*
	Função recebe as colunas da tabela e adiciona o componente de Skeleton para cada coluna,
	depois multiplica pela quantidade de linhas para que a tabela tenha x linhas com o Skeleton
*/

export const AddDataLoading = (columns: IColumn[], count: number) => {
    var objAux: any = []
    var dataLoading: any[] = []

    columns.map((x) => (objAux[x.dataIndex] = <Skeleton />))

    for (var x = 0; x < count; x++) {
        dataLoading.push({ ...objAux, key: `loading_${x}` })
    }

    return dataLoading
}
