import React, { FC, ReactNode } from 'react';
import { Icon, Text } from '../..';
import RandomBackground from './Components/RandomBackground';

import styles from './LoginLayout.module.scss';

export interface ISplitedLayouts {
	title: string;
	children: ReactNode;
	className?: string;
}

export const LoginLayout: FC<ISplitedLayouts> = ({ title, children, className }) => {
	return (
		<div className={styles['login-layout']}>
			<div className={styles['backgound-image']}>
				<RandomBackground />
			</div>
			<div className={styles['background']} />
			<div className={styles['page-login']}>
				<div className={styles['divLogin']}>
					<Icon className={styles['logo-bootys']} icon='logo-bootys-login' size='XXX' color='primary-base' />
					<Text className={`${className} ${styles['title']}`} type='heading2' color='text-50' >
						{title}
					</Text>
					{children}
					<Text className={styles['copyright']} type='link' color='text-400' children={`©${new Date().getFullYear()} Boötys. All rights reserved.`} />
				</div>
			</div>
		</div>
	);
}