export const accountManagerEN = {
    accountDetails: 'Account details',
    accountDetailsPage: {
        title: 'Profile',
        addPhoto: 'Add photo',
        name: 'Name',
        lastName: 'Last name',
        email: 'E-mail',
        birthday: 'Date of birth',
        phone: 'Telephone',
        edit: 'Edit',
        save: 'Save',
        cancel: 'Cancel',
        placeHolderEmail: 'your@email.com',
        placeHolderPhone: 'DDD + Telephone',

        errors: {
            requiredName: 'Name is required',
            requiredLastName: 'Last name is required',

            requiredMail: 'E-mail is required',
            invalidMail: 'Invalid e-mail',

            requiredBirthday: 'Birthday is required',
            invalidBirthday: 'Invalid date',
        },
    },

    accountSecurity: 'Account security',
    accountSecurityPage: {
        title: 'Change password',

        description:
            'To change your password, you need to verify the current one first. Then create a password that is not used elsewhere and change it regularly or whenever you suspect it has been discovered.',

        oldPassword: 'Current password',
        newPassword: 'New password',
        confirmPassword: 'Confirm the password',

        securityButton: 'Change password',

        errors: {
            invalidPassword: 'Invalid password',
            samePassword: 'Passwords do not match',
            requiredPassword: 'You need to confirm the password',
        },
    },

    accountNotification: 'Notifications',
}

export const accountManagerPT = {
    accountDetails: 'Detalhes da conta',
    accountDetailsPage: {
        title: 'Perfil',
        addPhoto: 'Adicionar foto',
        name: 'Nome',
        lastName: 'Sobrenome',
        email: 'E-mail',
        birthday: 'Data de nascimento',
        phone: 'Telefone',
        edit: 'Editar perfil',
        save: 'Salvar',
        cancel: 'Cancelar',
        placeHolderEmail: 'seu@email.com',
        placeHolderPhone: 'DDD + Telefone',

        errors: {
            requiredName: 'Nome é obrigatório',
            requiredLastName: 'Sobrenome é obrigatório',

            requiredMail: 'E-mail é obrigatório',
            invalidMail: 'E-mail inválido',

            requiredBirthday: 'E-mail é obrigatório',
            invalidBirthday: 'E-mail inválido',
        },
    },

    accountSecurity: 'Segurança da conta',
    accountSecurityPage: {
        title: 'Alterar senha',

        description:
            'Para alterar sua senha, é necessário verificar a atual primeiro. Em seguida, crie uma senha que não seja usada em outro lugar e altere-a regularmente ou sempre que suspeitar que ela foi descoberta.',

        oldPassword: 'Senha atual',
        newPassword: 'Nova senha',
        confirmPassword: 'Confirme a senha',

        securityButton: 'Alterar senha',

        errors: {
            invalidPassword: 'Senha inválida',
            samePassword: 'As senhas não são iguais',
            requiredPassword: 'Você precisa confirmar a senha',
        },
    },

    accountNotification: 'Notificações',
}
