import React, { ReactNode } from 'react';

import styles from './Flag.module.scss';
import './Flag.override.scss';

export interface IFlag {
  image: ReactNode;
}

export const Flag: React.FC<IFlag> = ({ image }) => {
  return (
    <div className={`flag-selected-dropdown ${styles['image']}`}>{image}</div>
  );
};
